<template>
  <div class="infoWrapper">
    <div class="customerInfo">
      <div class="sectionOne">
        <div class="sectionOneLeft">
          <div class="customerTitle">
            <span>{{ customer.name }}</span>
            <a
              :href="
                'https://www.google.se/maps/place/' +
                customer.address +
                ' ' +
                customer.zip
              "
              ><i class="fas fa-map-marker-alt"></i> {{ customer.address }}</a
            >
          </div>
          <div class="customerOptions">
            <!-- <span ><i class="fas fa-comment-alt"></i> Send message</span> -->
            <span @click="editContentValue" v-if="verifyPermission([2])"
              ><i class="fas fa-user-edit"></i> Edit customer
              <i
                :style="displayErrorSign"
                class="fas errorInfo fa-exclamation-circle"
              ></i
            ></span>
            <span
              @click="deleteUser"
              v-if="verifyPermission([2])"
              style="color: #ff8a8a"
              ><i class="fas fa-ban"></i> Delete customer</span
            >
          </div>
        </div>
        <!-- <div class="sectionOneRight">
          <div class="profilePicture">
            <img src="https://via.placeholder.com/150" alt="">
          </div>
        </div> -->
      </div>
      <div class="sectionTwo">
        <div>
          <div v-if="editCustomer" class="inputForms">
            <div>
              <p class="label">Name:</p>
              <input type="text" v-model="customerT.name" placeholder="Name" />
            </div>
            <div>
              <p class="label">Email:</p>
              <input
                :href="'mailto:' + customerT.email"
                type="text"
                v-model="customerT.email"
                placeholder="Email"
              />
            </div>
            <div>
              <p class="label">Zip:</p>
              <input type="text" v-model="customerT.zip" placeholder="Zip" />
            </div>
            <div>
              <p class="label">Phone:</p>
              <input type="text" v-model="customerT.tel" placeholder="Phone" />
            </div>
            <div>
              <p class="label">Address:</p>
              <input
                type="text"
                v-model="customerT.address"
                placeholder="Address"
              />
            </div>
          </div>
          <div v-if="!editCustomer" class="inputForms">
            <div>
              <p class="label">Name:</p>
              <a>{{ customer.name }}</a>
            </div>
            <div>
              <p class="label">Email:</p>
              <a :href="'mailto:' + customer.email">{{ customer.email }}</a>
            </div>
            <div>
              <p class="label">Zip:</p>
              <a>{{ customer.zip }}</a>
            </div>
            <div>
              <p class="label">Phone:</p>
              <a :href="'tel:' + customer.tel">{{ customer.tel }}</a>
            </div>
            <div>
              <p class="label">Address:</p>
              <a
                :href="
                  'https://www.google.se/maps/place/' +
                  customer.address +
                  ' ' +
                  customer.zip
                "
                >{{ customer.address }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="editCustomer" v-if="editCustomer">
        <div @click="updateCustomerInfo(true)" class="button success">
          <span><i class="fas fa-check"></i></span>
          <span>Update info</span>
        </div>
        <div @click="updateCustomerInfo(false)" class="button discard">
          <span><i class="fas fa-times"></i></span>
          <span>Discard info</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AuthService from '../../services/AuthService'

export default {
  data() {
    return {
      sendMessage: true,
      editCustomer: false,

      customer: {},
      customerT: {},

      customFields: [],
    }
  },
  computed: {
    displayErrorSign() {
      if (
        !this.customerT.name ||
        !this.customerT.email ||
        !this.customerT.address ||
        !this.customerT.zip ||
        !this.customerT.tel
      ) {
      } else {
        return { display: 'none' }
      }
    },
  },
  methods: {
    verifyPermission(neededPermissions) {
      return AuthService.verifyPermission(neededPermissions)
    },
    deleteUser() {
      var r = confirm('Do you want to delete this user?')
      if (r == true) {
        axios
          .post('customers/deleteCustomer', {
            customerId: this.$route.params.customerId,
          })
          .then(res => {
            this.$router.push({ name: 'Customers' })
          })
          .catch(err => {
            alert("Unauthorized")
          })
      }
    },
    updateCustomerInfo(val) {
      if (val) {
        axios.post('customers/updateCustomerInfo', {
          customerId: this.$route.params.customerId,
          customerInfo: this.customerT,
        })
          .then(res => {
            this.customer = this.customerT
            this.editContentValue()
          })
          .catch(err => {
            console.log(err.response);
            alert('Unauthorized')
          })
      } else {
        this.customerT = this.customer
        this.editContentValue()
      }
    },
    editContentValue() {
      this.editCustomer = !this.editCustomer
    },
    getCustomer() {
      const _this = this
      axios
        .post('customers/getCustomer', {
          customerId: this.$route.params.customerId,
        })
        .then(function (response) {
          _this.customer = response.data
          _this.customerT = _this.customer
        })
        .catch(function (error) {
          console.log(error)
        })
    },
  },
  created() {
    this.getCustomer()
  },
}
</script>

<style scoped lang="scss">
.sectionOne {
  display: inline-flex;
  width: 100%;
  border-bottom: 1px solid rgb(240, 240, 240);
  padding-top: 10px;
}

.sectionOneLeft {
  width: 100%;
}

.sectionOneRight {
  width: 20%;
  display: inline-flex;
}

.sectionTwo {
  display: flex;
}

.profilePicture {
  position: relative;
  margin: auto;
  text-align: center;
}

.profilePicture img {
  width: 100%;
  height: auto;
  max-height: 100px;
  max-width: 100px;
  transition: 0.2s;
  border-radius: 50%;
  cursor: pointer;
}

.profilePicture img:hover {
  width: 110px;
  height: 110px;
  filter: blur(1px);
}

.customerOptions {
  padding: 10px;
  padding-bottom: 20px;
}

.customerActive {
  background: rgb(38, 168, 255);
  border-radius: 5px;
  color: rgb(255, 255, 255) !important;
}

.customerOptions span {
  padding: 10px;
  cursor: pointer;
  font-size: 9pt;
  color: rgb(117, 117, 117);
  font-family: 'AvenirRound';
  font-weight: lighter;
  transition: 0.2s;
}

.customerOptions span:hover {
  color: rgb(41, 41, 41);
  font-size: 10pt;
}

.customerOptions span:nth-child(3):hover {
  color: #b90a0a !important;
}

.customerCompany span {
  font-size: 10pt;
  color: rgb(38, 168, 255);
  font-family: 'AvenirRound';
  font-weight: lighter;
  padding: 0px 10px 0px 10px;
}

.customerCompany {
  margin-bottom: 20px;
}

.customerTitle span {
  font-size: 20pt !important;
  color: rgb(77, 77, 77);
  font-family: 'AvenirRound';
  font-weight: lighter;
  padding: 0px 10px 0px 10px;
}

.customerTitle a {
  font-size: 8pt;
  color: rgb(165, 165, 165);
  font-family: 'AvenirRound';
  font-weight: lighter;
  padding: 0px 10px 0px 10px;
}

.button {
  display: inline;
  font-size: 8pt;
  color: white;
  cursor: pointer;
  margin: auto;
  padding: 10px;
}

.button span {
  color: white;
  padding: 7px 10px;
  text-align: center;
  justify-content: center;
}

.button span:nth-child(1) {
  border-radius: 3px 0px 0px 3px;
  background: rgb(14, 121, 209);
}

.button span:nth-child(2) {
  border-radius: 0px 3px 3px 0px;
  background: rgb(27, 152, 255);
}

.success span:nth-child(1) {
  background: rgb(27, 146, 11);
}

.success span:nth-child(2) {
  background: rgb(12, 175, 12);
}

.discard span:nth-child(1) {
  background: rgb(177, 12, 12);
}

.discard span:nth-child(2) {
  background: rgb(255, 27, 27);
}

.inputForms {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.inputForms div {
  display: inline-flex;
  width: 100%;
  height: 30px;
  line-height: 30px;
}
.inputForms div:nth-child(3) a {
  color: rgb(0, 162, 255);
}
.inputForms div:nth-child(5) a {
  color: rgb(0, 162, 255);
}

.inputForms p {
  color: rgb(54, 54, 54);
  border-collapse: collapse;
  font-family: 'AvenirRound';
  font-weight: bold;
  border: none;
  font-size: 10pt;
  transition: 0.2s ease-in-out;
  width: 100px;
}

.inputForms a {
  color: rgb(54, 54, 54);
  border-collapse: collapse;
  font-family: 'AvenirRound';
  border: none;
  font-size: 10pt;
  transition: 0.2s ease-in-out;
  width: 100%;
}

.inputForms input {
  color: rgb(54, 54, 54);
  border-collapse: collapse;
  font-family: 'AvenirRound';
  border: none;
  font-size: 10pt;
  transition: 0.2s ease-in-out;
  width: 100%;
  border-bottom: 1px solid rgb(226, 226, 226);
}

.customerInfo input:focus {
  border-bottom: 1px solid rgb(7, 164, 255);
}

.infoWrapper {
  border-radius: 7px;
  background: white;
  width: 100%;
  display: inline-flex;
  margin-right: 5px;
}

.customerInfo {
  position: relative;
  width: 100%;
  margin: 10px;
}

.editCustomer {
  margin-top: 10px;
}

.errorInfo {
  color: red;
  position: absolute;
}

@media screen and (max-width: 600px) {
  .profilePicture {
    margin: 0 !important;
  }

  .profilePicture img {
    width: 60px !important;
    height: 60px !important;
  }

  .customerTitle {
    display: grid;
  }

  .infoWrapper {
    border-right: none !important;
    border-radius: 5px !important;
  }

  .noteWrapper {
    border-radius: 5px !important;
  }
}
</style>