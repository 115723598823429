<template>
  <div class="noteWrapper">
    <div class="noteError" v-if="!notes">
      <div class="noteErrorWrapper">
        <p>This customer have no notes, click on add note to add a new note</p>
        <div @click="addNote" class="button">
          <span>+</span>
          <span>Add note</span>
        </div>
      </div>
    </div>
    <div class="noteSuccess" v-if="notes">
      <div class="noteW">
        <div class="note" v-for="note in notes" :key="note.id">
          <div v-if="note.displayNote == 1">
            <div @click="removeNote(note)" class="noteRemove">x</div>
            <textarea
              @blur="updateNote(note)"
              :noteId="note.id"
              class="noteDescription"
              v-model="note.description"
              placeholder="Description"
            />
          </div>
        </div>
        <div @click="addNote" class="addNewNote">
          <p>+</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      notes: undefined
    }
  },
  created() {
    this.fetchNotes()
  },
  methods: {
    updateNote(note) {
      axios
        .post('customers/notes/updateNote', {
          customerId: this.$route.params.customerId,
          noteId: note.id,
          noteData: note.description
        })
        .then((res) => {
          this.fetchNotes()
        })
    },
    removeNote(note) {
      axios
        .post('customers/notes/removeNote', {
          customerId: this.$route.params.customerId,
          noteId: note.id
        })
        .then(() => {
          this.fetchNotes()
        })
    },
    fetchNotes() {
      console.log("Hello");
      this.notes = []
      axios
        .post('customers/notes/getCustomerNotes', {
          customerId: this.$route.params.customerId
        })
        .then(res => {
          console.log(res.data);
          this.notes = res.data
        })
    },
    addNote() {
      axios
        .post('customers/notes/addNote', {
          customerId: this.$route.params.customerId
        })
        .then(res => {
          console.log(res);
          this.fetchNotes()
        })
    }
  }
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 600px) {
  .noteErrorWrapper {
    margin: auto 15px !important;
  }
}
.addNewNote {
  margin: 65px 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #333;
  color: white;
  position: relative;
  text-align: center;
  line-height: 40px;
  font-size: 20pt;
  transition: 0.2s ease-in-out;
}

.addNewNote:hover {
  width: 43px;
  height: 43px;
  line-height: 43px;
  background: #222;
}

.noteW {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.noteSuccess {
  position: relative;
  width: 100%;
}

.note {
  display: flex;
  position: relative;
  margin: 10px;
  height: 150px;
  width: 150px;
  background: #ffff88;
  border-radius: 7px;
  transition: 0.2s ease-in-out;
  color: #333;
  overflow: auto;
  padding: 5px;
}

.note .noteRemove {
  z-index: 10;
  cursor: pointer;
  line-height: 18px;
  color: white;
  font-size: 8pt;
  text-align: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #7c4f0b;
  position: absolute;
  top: 5px;
  right: 5px;
}

.note .noteDescription {
  font-size: 10pt;
  position: relative;
  background: none;
  border: none;
  height: 100%;
  width: 100%;
  padding:10px;

}

.noteWrapper {
  background: white;
  border-radius: 7px;
  width: 100%;
  display: inline-flex;
  margin-left: 5px;
}

.noteError {
  text-align: center;
  justify-content: center;
  margin: auto;
  height: 120px;
  display: inline-flex;
}

.noteErrorWrapper {
  text-align: center;
  margin: auto;
  width: 100%;
}

.noteError p {
  color: #333;
  font-size: 10pt;
}

.noteError .button {
  font-size: 8pt;
  color: white;
  cursor: pointer;
  margin: auto;
}

.noteError .button span {
  padding: 7px 10px;
}

.noteError .button span:nth-child(1) {
  border-radius: 3px 0px 0px 3px;
  background: rgb(14, 121, 209);
}

.noteError .button span:nth-child(2) {
  border-radius: 0px 3px 3px 0px;
  background: rgb(27, 152, 255);
}
</style>
