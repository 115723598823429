<template>
  <div>
    <div id="customerWrapper">
      <div class="pageWrapper" id="customerNotes">
        <CustomerInfo />
        <CustomerNotes />
      </div>
      <div id="customerOrders">
        <Orders :displayType="'all'" />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import CustomerNotes from '@/components/customers/CustomerNotes'
import CustomerInfo from '@/components/customers/CustomerInfo'
import Orders from '@/views/orders/Orders'

export default {
  components: {
    Loader,
    CustomerNotes,
    CustomerInfo,
    Orders,
  },
  data() {
    return {
      customer: undefined,
      customerOrder: undefined,
    }
  },
  created() {
    this.$socket.emit(
      'getCustomer',
      {
        companyId: this.$store.state.selectedUser.companyId,
        customerId: this.$route.params.customerId,
      },
      (callbackData) => {
        this.customer = callbackData
      }
    )
  },
}
</script>

<style scoped lang="scss">
.pageWrapper {
  padding-bottom: 0;
}

#customerWrapper {
  width: 100%;
  display: inline-block;
  position: relative;
  margin-top: 20px;
}

#customerWrapper div {
  margin-bottom: 5px;
}

#customerInfo {
  width: 100%;
  position: relative;
  height: auto;
}

#customerNotes {
  width: 100%;
  position: relative;
  height: auto;
  display: inline-flex;
}

@media screen and (max-width: 600px) {
  #customerNotes {
    display: inline-block;
  }
}
</style>